import { useState } from 'react'
import { Grid, FormControlLabel, Switch, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { useFetch } from 'hooks'

import { Card, Label } from 'components'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

const Partner = ({ control, disabled, errors, watch, paymentContract }) => {
  const [nameds, setNameds] = useState(null)

  const observable = nameds?.length >= 3

  const { response, isLoading } = useFetch(
    service.dponet.sellers.index,
    {
      kindNotIn: [constants.seller.SELLER_KIND],
      perPage: 10000,
      name: nameds,
      order: 'name ASC',
    },
    [nameds],
    observable,
  )

  const partners = isLoading ? [] : response?.data?.sellers

  const isPartner = watch('isPartner')

  const transferCommission = paymentContract?.transferCommision

  return (
    <Card title="Parceiros">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Controller
                as={<Switch color="primary" />}
                name="isPartner"
                control={control}
                onChange={([event]) => event.target.checked}
              />
            }
            label="Venda efetuada com indicação de parceiro"
          />
        </Grid>
        {isPartner && (
          <Grid item xs={12}>
            <Controller
              as={
                <Autocomplete
                  options={partners}
                  getOptionLabel={(option) => option?.name}
                  noOptionsText={
                    !observable && 'Digite pelo menos 3 caracteres para buscar'
                  }
                  getOptionSelected={(option, value) =>
                    !value || option?.id === value?.id || value?.id === 0
                  }
                  disabled={disabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors?.partner}
                      helperText={errors?.partner?.message}
                      label="Selecione um parceiro"
                      variant="outlined"
                      onChange={(e) => setNameds(e.target.value)}
                    />
                  )}
                />
              }
              control={control}
              name="partner"
              mode="onChange"
              onChange={([, data]) => {
                return data
              }}
            />
          </Grid>
        )}
        {transferCommission && (
          <>
            <Grid item xs={12}>
              <Label title="Comissão do parceiro" />
            </Grid>
            <Grid item xs={3}>
              <Label title="Nível" description={transferCommission?.level} />
            </Grid>
            <Grid item xs={3}>
              <Label
                title="De"
                description={helpers.sellers.defaultValues(
                  transferCommission?.kind,
                  transferCommission?.rangeFrom,
                  0,
                  true,
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Label
                title="Até"
                description={helpers.sellers.defaultValues(
                  transferCommission?.kind,
                  transferCommission?.rangeTo,
                  0,
                  true,
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Label
                title="Porcentagem"
                description={helpers.sellers.defaultValues(
                  'percentage',
                  transferCommission?.percentage,
                  0,
                  true,
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  )
}

Partner.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  watch: PropTypes.func.isRequired,
}

export default Partner
