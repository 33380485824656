import { useState } from 'react'
import { Grid, TextField, Box, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'
import { Alert } from '@material-ui/lab'
import Moment from 'moment'
import { useHistory } from 'react-router-dom'
import InfoIcon from '@material-ui/icons/Info'

import { useSnackbar } from 'hooks'

import { ButtonComponent, Select, Card, InfoDialog } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'

import schema from './schema'

const Form = ({ paymentOrder }) => {
  const snackbar = useSnackbar()
  const history = useHistory()

  const [infoOpen, setInfoOpen] = useState({ open: false, text: '', title: '' })

  const { control, errors, watch, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      status: paymentOrder?.status || '',
      price: helpers.formatters.currencyInput(paymentOrder?.price || ''),
      gateway: paymentOrder?.gateway || '',
      paymentMethod: paymentOrder?.paymentMethod || '',
      paidDate: paymentOrder?.paidDate ? Moment(paymentOrder?.paidDate) : null,
      withdrawalDate: paymentOrder?.withdrawalDate
        ? Moment(paymentOrder?.withdrawalDate)
        : null,
      orderReferenceDate: Moment(paymentOrder?.orderReferenceDate),
      expirationDate: paymentOrder?.expirationDate
        ? Moment(paymentOrder?.expirationDate)
        : null,
      paidDateClient: paymentOrder?.paidDateClient,
    },
  })

  const isCard = watch('paymentMethod') === constants.paymentOrder.CARD_METHOD

  const onSubmit = async (data) => {
    try {
      await service.dponet.paymentOrders.edit({
        paymentOrderId: paymentOrder.id,
        paymentOrder: helpers.paymentOrders.mount(data),
      })

      snackbar.open({
        message: 'Ordem de pagamento salva com sucesso!',
        variant: 'success',
      })

      history.goBack()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Ocorreu um erro ao tentar salvar a ordem de pagamento!',
        ),
        variant: 'error',
      })
    }
  }

  const handleInfoPaidClient = () => {
    setInfoOpen({
      open: true,
      text: constants.paymentOrder.DESCRIPTION_PAID_CLIENT,
      title: 'Data de pagamento (cliente)',
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card title="Formulário">
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Alert severity="info">
                <Typography variant="body2">
                  As alterações abaixo não acionam gatilhos, como a baixa no
                  OMIE. No entanto, ao modificar o status, podem impactar
                  funcionalidades, como a interrupção do envio de e-mails de
                  inadimplência.
                </Typography>
              </Alert>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Controller
                name="status"
                control={control}
                as={
                  <Select
                    fullWidth
                    items={constants.paymentOrder.STATUS_OPTIONS}
                    label="Status"
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <Controller
                name="price"
                control={control}
                mode="onChange"
                onChange={([event]) =>
                  helpers.formatters.currencyInput(event.target.value)
                }
                as={
                  <TextField
                    label="Valor"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors?.price}
                    helperText={errors?.price?.message}
                    fullWidth
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <Controller
                name="gateway"
                control={control}
                as={
                  <Select
                    fullWidth
                    items={constants.paymentOrder.GATEWAYES}
                    label="Intermediador"
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <Controller
                name="paymentMethod"
                control={control}
                as={
                  <Select
                    fullWidth
                    items={constants.paymentOrder.PAYMENT_METHODS}
                    label="Meio de pagamento"
                  />
                }
              />
            </Grid>

            <Grid xs={12} sm={6} item>
              <Controller
                name="paidDate"
                control={control}
                as={
                  <DatePicker
                    label="Data do pagamento (gateway)"
                    color="primary"
                    format="DD/MM/YYYY"
                    variant="dialog"
                    fullWidth
                    onChange={(newValue) => ({ value: newValue })}
                    clearable
                  />
                }
              />
            </Grid>

            <Grid xs={12} sm={6} item>
              <Controller
                name="withdrawalDate"
                control={control}
                as={
                  <DatePicker
                    label="Data do saque"
                    color="primary"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    fullWidth
                    onChange={(newValue) => ({ value: newValue })}
                    clearable
                  />
                }
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <Controller
                name="orderReferenceDate"
                control={control}
                as={
                  <DatePicker
                    label="Data de referência da ordem"
                    color="primary"
                    format="MM/YYYY"
                    variant="outlined"
                    views={['year', 'month']}
                    fullWidth
                    onChange={(newValue) => ({ value: newValue })}
                  />
                }
              />
            </Grid>
            {!isCard && (
              <Grid xs={12} sm={6} item>
                <Controller
                  name="expirationDate"
                  control={control}
                  as={
                    <DatePicker
                      label="Data de vencimento"
                      color="primary"
                      format="DD/MM/YYYY"
                      variant="outlined"
                      fullWidth
                      onChange={(newValue) => ({ value: newValue })}
                    />
                  }
                />
              </Grid>
            )}
            <Grid xs={12} sm={6} item>
              <Controller
                name="paidDateClient"
                control={control}
                as={
                  <DatePicker
                    disabled
                    label="Data de pagamento (cliente)"
                    color="primary"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    fullWidth
                    onChange={(newValue) => ({ value: newValue })}
                    InputProps={{
                      endAdornment: (
                        <InfoIcon
                          color="primary"
                          size={10}
                          onClick={handleInfoPaidClient}
                        />
                      ),
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Card>
        <Box display="flex" justifyContent="flex-end">
          <ButtonComponent
            type="submit"
            variant="contained"
            color="primary"
            title="Salvar"
          />
        </Box>
      </form>
      {infoOpen?.open && (
        <InfoDialog
          open={infoOpen?.open}
          setOpen={setInfoOpen}
          text={infoOpen?.text}
          title={infoOpen?.title}
          typeObject
        />
      )}
    </>
  )
}

export default Form
