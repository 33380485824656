import api from 'service/api'

const dponetAPI = api.create('dponet')

const index = async (params) => {
  return await dponetAPI.get('/sellers', { params })
}

const create = async (data) => {
  return await dponetAPI.post('/sellers', { seller: data })
}

const update = async ({ sellerId, ...data }) => {
  return await dponetAPI.put(`/sellers/${sellerId}`, { seller: data })
}

const show = async (sellerId) => {
  return await dponetAPI.get(`/sellers/${sellerId}`)
}

const reports = async (params) => {
  return await dponetAPI.get('seller_reports', {
    params,
  })
}

const sellers = {
  index,
  create,
  update,
  show,
  reports,
}

export default sellers
