import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/sebrae/consult`, { params })
}

const sebrae = {
  get,
}

export default sebrae
