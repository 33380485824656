const CONSULTIVE_SALES_LABEL = 'Consultive Sales'
const PUBLIC_AGENCY_LABEL = 'Órgãos Públicos'
const DIGITAL_SALES_LABEL = 'Digital Sales'
const SERVICES_LABEL = 'Serviços'

const TYPE_SERVICES_OPTIONS = [
  { id: 'Digital Sales', name: 'Digital Sales' },
  { id: 'Consultive Sales', name: 'Consultive Sales' },
]

const VERTICAL_DIGITAL_SALES_OPTIONS = [
  {
    id: 'Saúde - Operadora',
    name: 'Saúde - Operadora',
  },
  {
    id: 'Saúde - Clínica Médica',
    name: 'Saúde - Clínica Médica',
  },
  {
    id: 'Saúde - Hospital/Laboratório',
    name: 'Saúde - Hospital/Laboratório',
  },
  { id: 'Diversos', name: 'Diversos' },
  { id: 'Serviços', name: 'Serviços' },
  { id: 'Contabilidade', name: 'Contabilidade' },
  { id: 'Concessionária', name: 'Concessionária' },
  { id: 'Condomínio', name: 'Condomínio' },
]

const VERTICAL_CONSULTIVE_SALES_OPTIONS = [
  { id: 'Órgãos Públicos', name: 'Órgãos Públicos' },
]

const VERTICAL_OPTIONS = {
  'Digital Sales': VERTICAL_DIGITAL_SALES_OPTIONS,
  'Consultive Sales': VERTICAL_CONSULTIVE_SALES_OPTIONS,
}

const TABS = [
  {
    value: 'contract',
    label: 'Contrato',
  },
  {
    value: 'contractor',
    label: 'Empresas',
  },
  {
    value: 'payment',
    label: 'Pagamento',
  },
  {
    value: 'paymentOrder',
    label: 'Cobranças',
  },
]

const PAYMENT_METHODS = {
  billet: 'Boleto',
  card: 'Cartão',
  pix: 'Pix',
}

const KIND_ACTIVE = ['initial', 'recurrence', 'manual']
const INDEX_TYPE_OPTIONS = [
  { id: 'igpm', name: 'IGP-M' },
  { id: 'ipca', name: 'IPCA' },
]

const DESCRIPTION_POSTPONE_OR_ANTICIPATE =
  "A opção 'Prorrogar' move o vencimento para a segunda seguinte,  " +
  "e 'Antecipar' para a sexta anterior.  Se cair no último dia do mês, " +
  'o vencimento será mantido, mesmo que seja fim de semana, ' +
  'para evitar duas cobranças no mesmo mês. '

const paymentContract = {
  TYPE_SERVICES_OPTIONS,
  VERTICAL_OPTIONS,
  CONSULTIVE_SALES_LABEL,
  PUBLIC_AGENCY_LABEL,
  DIGITAL_SALES_LABEL,
  SERVICES_LABEL,
  PAYMENT_METHODS,
  TABS,
  KIND_ACTIVE,
  INDEX_TYPE_OPTIONS,
  DESCRIPTION_POSTPONE_OR_ANTICIPATE,
}

export default paymentContract
