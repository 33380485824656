import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async (params) => {
  return await dponetAPI.get(`/integration_partners/webhooks`, {
    params,
  })
}

const create = async ({ integrationPartnerId, integrationPartnerWebhook }) => {
  return await dponetAPI.post(
    `/integration_partners/${integrationPartnerId}/webhooks`,
    {
      integrationPartnerWebhook,
    },
  )
}
const destroy = async ({
  integrationPartnerId,
  integrationPartnerWebhookId,
}) => {
  return await dponetAPI.delete(
    `/integration_partners/${integrationPartnerId}/webhooks/${integrationPartnerWebhookId}`,
  )
}

const integrationPartners = {
  get,
  create,
  destroy,
}

export default integrationPartners
