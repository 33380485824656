import * as yup from 'yup'

const schema = yup.object().shape({
  paymentContract: yup.object().shape({
    id: yup.string().required(),
    name: yup.string().required(),
  }),
  origin: yup.string().required('Origem é obrigatória'),
})

export default schema
