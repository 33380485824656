import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

import { BaseDialog } from 'components'

import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import * as service from 'service'

const EditBillet = ({ open, setOpen, refresh, setLoading, paymentOrder }) => {
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      expirationDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      price: helpers.formatters.currencyInput(paymentOrder.price),
    },
  })

  const isEdit = paymentOrder?.billet

  const onSubmit = async (data) => {
    setLoading(true)

    data.expirationDate = moment(data.expirationDate).format('YYYY-MM-DD')
    data.price = helpers.formatters.currencyOutput(data.price)

    try {
      await service.dponet.paymentOrders.editBillet({
        paymentOrderId: paymentOrder.id,
        ...data,
      })

      snackbar.open({
        message: `Boleto ${isEdit ? 'editado' : 'gerado'} com sucesso!`,
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            `Erro ao ${
              isEdit ? 'editar' : 'gerar'
            } o boleto, tente novamente mais tarde!`,
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      title={`${isEdit ? 'Editar' : 'Gerar'} boleto`}
      actionButtonText="Confirmar"
      closeButtonText="Cancelar"
      setOpen={setOpen}
      formRef="id-edit-billet"
    >
      <form onSubmit={handleSubmit(onSubmit)} id="id-edit-billet">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <Controller
              as={
                <DatePicker
                  label="Data de vencimento"
                  color="primary"
                  format="DD/MM/YYYY"
                  variant="outlined"
                  fullWidth
                  disablePast
                  onChange={(newValue) => ({ value: newValue })}
                  minDate={moment().add(1, 'days')}
                />
              }
              control={control}
              name="expirationDate"
              mode="onChange"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  fullWidth
                  label="Valor do boleto"
                  type="text"
                  error={!!errors.price}
                  helperText={errors?.price?.message}
                />
              }
              control={control}
              name="price"
              mode="onChange"
              onChange={([event]) => {
                return helpers.formatters.currencyInput(event.target.value)
              }}
            />
          </Grid>
        </Grid>
      </form>
    </BaseDialog>
  )
}

EditBillet.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  setLoading: PropTypes.func,
  paymentOrder: PropTypes.object,
}

export default EditBillet
