import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  textTitle: {
    color: theme.palette.BlueDownDark,
    fontSize: theme.spacing(2),
    fontWeight: theme.spacing(75),
  },
}))
export default styles
