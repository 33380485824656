import * as yup from 'yup'

const schema = yup.object().shape({
  contractIdentifier: yup.string().required(),
  typeService: yup.string().required().typeError('Escolha um tipo de serviço'),
  vertical: yup.string().required().typeError('Escolha uma vertical'),
  generateContract: yup.boolean(),
  clientEmail: yup.string().when('generateContract', {
    is: true,
    then: yup.string().required(),
  }),
  periodOnMonth: yup
    .number()
    .min(1)
    .max(60)
    .required()
    .typeError('Escolha um período'),
  indexType: yup.string().required().typeError('Escolha um índice de reajuste'),
  dpoServiceEndDate: yup
    .date()
    .when('dpoServiceApply', {
      is: true,
      then: yup.date().required().typeError('Escolha uma data'),
    })
    .nullable(),
  dpoServicePrice: yup.string().when('dpoServiceApply', {
    is: true,
    then: yup.string().required(),
  }),
})

export default schema
