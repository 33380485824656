import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import { Card, SkeletonInputs } from 'components'

import clsx from 'clsx'

import useStyles from './styles'

import helpers from 'helpers'
import externalAPIs from 'service/modules/externalAPIs'

const Address = ({
  control,
  errors,
  getValues,
  addressData,
  setValue,
  watch,
}) => {
  const [postalCodeEmpty, setPostalCodeEmpty] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const setAddressValues = (dataResponse) => {
    if (dataResponse) {
      setValue([
        { country: 'Brasil' },
        { city: dataResponse.localidade },
        { state: dataResponse.uf },
        { neighborhood: dataResponse.bairro },
        { street: dataResponse.logradouro },
      ])
      setPostalCodeEmpty(false)
    } else if (addressData && !dataResponse) {
      setValue([
        { country: 'Brasil' },
        { country: addressData?.country },
        { city: addressData?.city },
        { state: addressData?.state },
        { neighborhood: addressData?.neighborhood },
        { street: addressData?.street },
      ])
      setPostalCodeEmpty(false)
    } else {
      setValue([
        { country: 'Brasil' },
        { country: '' },
        { city: '' },
        { state: '' },
        { neighborhood: '' },
        { street: '' },
      ])
    }
  }

  useEffect(() => {
    const getAddressByPostalCode = async (cep) => {
      setIsLoading(true)
      const response = await externalAPIs.viaCEP.get(cep)
      setIsLoading(false)
      if (!response?.data || response?.data?.erro) {
        setPostalCodeEmpty(false)
        setAddressValues(false)
      }
      setAddressValues(response?.data)
    }

    const postalCode = getValues('postalCode')

    if (postalCode.length === 9) {
      getAddressByPostalCode(postalCode)
    } else {
      setPostalCodeEmpty(true)
      setAddressValues(false)
    }
    // eslint-disable-next-line
  }, [watch('postalCode')])

  return (
    <Card title="Preencha o endereço da empresa">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="postalCode"
            mode="onChange"
            as={
              <TextField
                label="Código postal (CEP)"
                fullWidth
                variant="outlined"
                error={!!errors?.postalCode}
                helperText={errors?.postalCode?.message}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.cep(event.target.value)
            }}
          />
        </Grid>
        {isLoading && <SkeletonInputs grids={[12, 6, 6, 6, 6, 6, 6]} />}
        <Grid
          className={clsx(classes.gridAddress, {
            [classes.gridAddressHidden]: postalCodeEmpty,
          })}
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <Controller
              control={control}
              name="street"
              mode="onChange"
              as={
                <TextField
                  label="Rua/Logradouro"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.street}
                  helperText={errors?.street?.message}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="number"
              mode="onChange"
              as={
                <TextField
                  label="Número"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.number}
                  helperText={errors?.number?.message}
                  inputProps={{ maxLength: 10 }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="neighborhood"
              mode="onChange"
              as={
                <TextField
                  label="Bairro"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.neighborhood}
                  helperText={errors?.neighborhood?.message}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="complement"
              mode="onChange"
              as={
                <TextField
                  label="Complemento"
                  fullWidth
                  variant="outlined"
                  error={!!errors.complement}
                  helperText={errors?.complement?.message}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="state"
              mode="onChange"
              type="text"
              as={
                <TextField
                  label="Estado, ex: SP"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.state}
                  helperText={errors?.state?.message}
                  inputProps={{
                    maxLength: 2,
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="country"
              mode="onChange"
              as={
                <TextField
                  label="País"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.country}
                  helperText={errors?.country?.message}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="city"
              mode="onChange"
              as={
                <TextField
                  label="Cidade"
                  fullWidth
                  variant="outlined"
                  error={!!errors?.city}
                  helperText={errors?.city?.message}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

Address.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

export default Address
