import { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import { useCompany, useSnackbar } from 'hooks'
import { useCompaniesCache } from 'hooks/caches'

import { LoadingFeedback } from 'components'
import { JuridicOrPersonalForm, TemplateForm, SupplierForm } from './components'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const CompanyForm = ({ editionMode, editionPermitted, companyData }) => {
  const [company, setCompany] = useState(companyData)
  const [loading, setLoading] = useState(false)
  const [loadingSupplier, setLoadingSupplier] = useState(false)

  const companiesCache = useCompaniesCache()
  const snackbar = useSnackbar()
  const location = useLocation()
  const { isLoading } = useCompany()

  const typeDefault = location?.state?.type

  const refresh = () => {
    companiesCache.useUpdateCache(company?.id)
  }

  const isKindPersonal = typeDefault === constants.companies.KINDS.PERSONAL
  const isKindTemplate = typeDefault === constants.companies.KINDS.TEMPLATE
  const isKindSupplier = company?.kind === constants.companies.KINDS.SUPPLIER

  const loadSupplierData = async (document, setValue) => {
    if (!editionMode) {
      try {
        setLoading(true)
        const response = await service.dponet.companies.verifyDocument(document)
        const supplierCompany = response?.data?.company

        if (!!supplierCompany?.id && company?.id !== supplierCompany.id) {
          setLoadingSupplier(true)
          setCompany({
            ...supplierCompany,
            statusId: constants.companies.STATUSES.ONBOARDING,
          })
          setLoadingSupplier(false)

          snackbar.open({
            message:
              'O documento informado foi identificado sendo pertencente de uma empresa fornecedora, portanto os dados da mesma foram carregados',
            variant: 'warning',
          })
        }
        setLoading(false)
      } catch {
        if (!!company?.id) {
          setLoadingSupplier(true)
          const field = isKindPersonal ? 'cpf' : 'cnpj'
          setValue(field, document)
          setCompany({
            [field]: helpers.formatters.cnpjAndCpfMask(document),
          })
          setLoadingSupplier(false)
        }
        setLoading(false)
      }
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading || loading} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!isKindTemplate && !isKindSupplier && !loadingSupplier && (
            <JuridicOrPersonalForm
              company={company}
              setLoading={setLoading}
              disableCertFields={editionMode}
              editionPermitted={editionPermitted}
              refresh={refresh}
              loadSupplierData={loadSupplierData}
              typeDefault={typeDefault}
            />
          )}
          {isKindTemplate && (
            <TemplateForm
              company={company}
              setLoading={setLoading}
              disableCertFields={editionMode}
              editionPermitted={editionPermitted}
              refresh={refresh}
            />
          )}
          {isKindSupplier && (
            <SupplierForm
              company={company}
              setLoading={setLoading}
              disableCertFields={editionMode}
              editionPermitted={editionPermitted}
              refresh={refresh}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

CompanyForm.propTypes = {
  editionMode: PropTypes.bool,
  editionPermitted: PropTypes.bool,
  companyData: PropTypes.object,
}

CompanyForm.defaultProps = {
  editionMode: false,
}

export default CompanyForm
