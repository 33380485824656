import { routes } from 'Routes'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import { Permitted } from 'components'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const ActionButtons = ({ churn, refresh, setIsLoading }) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const handleEdit = () => {
    history.push(
      reverse(routes.churns.edit, {
        churnId: churn?.id,
      }),
    )
  }

  const handleView = () => {
    history.push(
      reverse(routes.churns.show, {
        churnId: churn?.id,
      }),
    )
  }

  const handleDestroy = async () => {
    setIsLoading(true)
    try {
      await service.dponet.churns.destroy({ churnId: churn?.id })

      snackbar.open({
        message: 'Churn excluída com sucesso!',
        variant: 'success',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao excluir a churn, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Permitted tag={constants.permissions.CHURNS.SHOW}>
        <Button fullWidth size="small" onClick={handleView}>
          Visualizar
        </Button>
      </Permitted>
      <Permitted tag={constants.permissions.CHURNS.MANAGE}>
        <Button
          fullWidth
          size="small"
          onClick={handleEdit}
          disabled={!constants.churn.STATUSES_INITIAL.includes(churn?.status)}
        >
          Editar
        </Button>
        <Button fullWidth size="small" onClick={handleDestroy}>
          Excluir
        </Button>
      </Permitted>
    </>
  )
}

ActionButtons.propTypes = {
  churn: PropTypes.object,
  refresh: PropTypes.func,
  setIsLoading: PropTypes.func,
}

export default ActionButtons
