import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Box,
  Hidden,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import { Controller } from 'react-hook-form'

import helpers from 'helpers'
import useStyles from '../../styles'

const AddCommision = ({ loading, control, errors, watch, isEdit }) => {
  const classes = useStyles()

  const quantity = watch('kind') === 'quantity'

  return (
    <Grid container spacing={2}>
      <Grid item xs={isEdit ? 12 : 3}>
        <Controller
          control={control}
          name="level"
          mode="onChange"
          as={
            <TextField
              InputProps={{
                startAdornment: <Box mr={1}>Nível</Box>,
              }}
              type="text"
              label="Nível"
              fullWidth
              variant="outlined"
              disabled={loading}
            />
          }
        />
      </Grid>
      <Grid item xs={isEdit ? 12 : 3}>
        <Controller
          control={control}
          name="rangeFrom"
          mode="onChange"
          onChange={([event]) =>
            helpers.sellers.rangeType(watch('kind'), event.target.value)
          }
          as={
            <TextField
              type="text"
              label="De"
              fullWidth
              variant="outlined"
              error={!!errors?.rangeFrom}
              helperText={errors?.rangeFrom?.message}
              disabled={loading}
              InputProps={{
                startAdornment: <Box mr={1}>{quantity ? '' : 'R$'}</Box>,
              }}
              inputProps={{
                maxLength: quantity ? 8 : 12,
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={isEdit ? 12 : 3}>
        <Controller
          control={control}
          name="rangeTo"
          mode="onChange"
          onChange={([event]) =>
            helpers.sellers.rangeType(watch('kind'), event.target.value)
          }
          as={
            <TextField
              type="text"
              label="Até"
              fullWidth
              variant="outlined"
              error={!!errors?.rangeTo}
              helperText={errors?.rangeTo?.message}
              disabled={loading}
              InputProps={{
                startAdornment: <Box mr={1}>{quantity ? '' : 'R$'}</Box>,
              }}
              inputProps={{
                maxLength: quantity ? 8 : 12,
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={isEdit ? 12 : 1}>
        <Controller
          control={control}
          name="percentage"
          mode="onChange"
          onChange={([event]) =>
            helpers.sellers.rangeType('percentage', event.target.value)
          }
          rules={{
            pattern: {
              value: /^(100|[1-9]?\d(\.\d{1,2})?)$/,
              message:
                'Por favor, insira um valor válido (máximo 100 e minimo 0) ' +
                'levando em consideração até duas casas decimais (ex: 10.98)',
            },
          }}
          as={
            <TextField
              type="text"
              label="Comissão (%)"
              fullWidth
              variant="outlined"
              error={!!errors?.percentage}
              helperText={errors?.percentage?.message}
              disabled={loading}
              InputProps={{
                endAdornment: <Box mr={1}>%</Box>,
              }}
            />
          }
        />
      </Grid>
      {!isEdit && (
        <Grid item xs={2}>
          <Button
            fullWidth
            className={classes.btn}
            startIcon={
              loading ? (
                <CircularProgress color="white" size={20} />
              ) : (
                <AddIcon />
              )
            }
            disabled={loading}
            variant="contained"
            color="primary"
            type="submit"
          >
            <Hidden mdDown>Adicionar nível</Hidden>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default AddCommision
