import { Grid } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import { Select } from 'components'
import constants from 'constants/index'

const Kinded = ({ control, disabled }) => {
  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="kind"
        mode="onChange"
        as={
          <Select
            label="Tipo"
            fullWidth
            variant="outlined"
            items={constants.seller.KINDS}
            disabled={disabled}
          />
        }
      />
    </Grid>
  )
}

export default Kinded
