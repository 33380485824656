import { useState } from 'react'
import { Plus as PlusIcon } from 'react-feather'
import { Button, TablePagination } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { Card, LoadingFeedback, MenuButton } from 'components'
import { CreateDialog } from './components'

import { usePagination, useSnackbar, useFetch } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const IntegrationWebhookTable = ({ integrationPartnerId }) => {
  const [openCreationDialog, setOpenCrationDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()
  const snackbar = useSnackbar()

  const { response, isLoading, refresh } = useFetch(
    service.dponet.integrationPartnerWebhooks.get,
    { integrationPartnerId, page, perPage },
    [page, perPage],
  )

  const integrationPartnerWebhooks = response?.data?.integrationPartnerWebhooks

  const handleOpenCreationDialog = () => {
    setOpenCrationDialog(true)
  }

  const handleCloseCreationDialog = () => {
    setOpenCrationDialog(false)
  }

  const handleDeleteWebhook = async (webhookId) => {
    setLoading(true)

    try {
      await service.dponet.integrationPartnerWebhooks.destroy({
        integrationPartnerId,
        integrationPartnerWebhookId: webhookId,
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao excluir webhook',
        variant: 'error',
      })
    }

    setLoading(false)
  }

  return (
    <Card
      title="Webhooks"
      actionButton={
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusIcon />}
          onClick={handleOpenCreationDialog}
        >
          Criar
        </Button>
      }
      dropdown
      defaultExpanded
    >
      <LoadingFeedback open={loading} />

      <Table
        emptyMessage="Nenhum webhook encontrado encontrado"
        isLoading={isLoading}
      >
        <TableHead>
          <TableRow>
            <TableCell width="10%">ID</TableCell>
            <TableCell width="40%">Tipo</TableCell>
            <TableCell width="40%">URL</TableCell>
            <TableCell width="10%" align="right">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {integrationPartnerWebhooks?.map((webhook) => (
            <TableRow key={webhook.id} hover>
              <TableCell disableTooltip>{webhook.id}</TableCell>
              <TableCell noOverFlow disableTooltip>
                {
                  constants.integrationPartner.WEBHOOK_EVENT_LABELS[
                    webhook.event
                  ]
                }
              </TableCell>
              <TableCell noOverFlow disableTooltip>
                {webhook.url}
              </TableCell>
              <TableCell disableTooltip align="right">
                <MenuButton>
                  <Button
                    size="small"
                    onClick={() => handleDeleteWebhook(webhook.id)}
                  >
                    Excluir
                  </Button>
                </MenuButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={response?.data?.meta?.totalCount || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        rowsPerPage={perPage}
        rowsPerPageOptions={[5, 10, 25, 100]}
        labelRowsPerPage="Por página"
        nextIconButtonProps={{ size: 'small' }}
        backIconButtonProps={{ size: 'small' }}
      />

      {openCreationDialog && (
        <CreateDialog
          open={openCreationDialog}
          handleClose={handleCloseCreationDialog}
          integrationPartnerId={integrationPartnerId}
          refresh={refresh}
        />
      )}
    </Card>
  )
}

export default IntegrationWebhookTable
