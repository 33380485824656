import { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'

import useSnackbar from 'hooks/useSnackbar'

import { BaseDialog, LoadingFeedback } from 'components'

import * as service from 'service'
import helpers from 'helpers'

const ExportDialog = ({ open, setOpen, params }) => {
  const [isLoading, setIsloading] = useState(false)
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      referenceDate: moment().subtract(1, 'month'),
    },
  })

  const onSubmit = async (data) => {
    setIsloading(true)
    try {
      const referenceDate = moment(data.referenceDate).format('YYYY-MM-DD')
      await service.dponet.sellers.reports({ ...params, referenceDate })

      snackbar.open({
        message:
          'Relatório foi gerado com sucesso e enviado para o e-mail do usuário logado!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha na geração do relatório!',
        variant: 'error',
      })
    } finally {
      setOpen(false)
      setIsloading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <BaseDialog
        title="Exportar contratos"
        actionButtonText="Exportar"
        closeButtonText="Cancelar"
        open={open}
        setOpen={setOpen}
        formRef="export-commisstion-report"
      >
        <form onSubmit={handleSubmit(onSubmit)} id="export-commisstion-report">
          <Grid container spacing={2}>
            <Grid item xs="12">
              <Controller
                as={
                  <KeyboardDatePicker
                    label="Mês/Ano de Referência"
                    color="primary"
                    format="MM/yyyy"
                    variant="dialog"
                    views={['year', 'month']}
                    error={!!errors.referenceDate}
                    helperText={errors?.referenceDate?.message}
                    fullWidth
                    onChange={(newValue) => ({ value: newValue })}
                  />
                }
                control={control}
                name="referenceDate"
                mode="onChange"
              />
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  )
}

ExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  params: PropTypes.object,
}

export default ExportDialog
