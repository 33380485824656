import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    cursor: 'pointer',
    margin: '0px 0px 0px 10px',
  },
}))

export default useStyles
