import { isEmpty } from 'lodash'
import constants from 'constants/index'

import moment from 'moment'

const { STATUSES, KIND_TRIAL_LIMITED } = constants.preRegistration

const mountTrial = (data) => {
  const {
    sellerId,
    fantasyName,
    document,
    email,
    phone,
    planId,
    name,
    kind,
    type,
    responsibleEmail,
    responsibleName,
    responsiblePhone,
    endDate,
    postalCode,
    country,
    state,
    city,
    neighborhood,
    street,
    number,
    complement,
    typeClient,
    typeService,
    vertical,
    companyTemplate,
    typeTrial,
    beneficiarySebrae,
  } = data

  const isAddressEmpty =
    isEmpty(postalCode) ||
    isEmpty(country) ||
    isEmpty(state) ||
    isEmpty(city) ||
    isEmpty(neighborhood) ||
    isEmpty(street) ||
    isEmpty(number)

  const address = isAddressEmpty
    ? null
    : {
        postalCode: postalCode.replace(/\D/g, ''),
        country,
        state: state.toUpperCase(),
        city,
        neighborhood,
        street,
        number,
        complement,
      }

  const responsibleUser = {
    email: responsibleEmail.toLowerCase(),
    name: responsibleName,
    phone: responsiblePhone.replace(/\D/g, ''),
  }

  return {
    name: name || fantasyName,
    fantasyName,
    document,
    kind,
    phone,
    email,
    planId,
    type,
    typeService,
    vertical,
    typeClient,
    responsibleUser,
    sellerId,
    demoPartner: {
      endDate: moment(endDate).format('DD/MM/YYYY'),
      kind: typeTrial,
    },
    companyTemplateId: companyTemplate?.id,
    address: address,
    status:
      typeTrial === KIND_TRIAL_LIMITED
        ? STATUSES.TRIAL_LIMITED_STATUS
        : STATUSES.TRIAL_COMPLETE_STATUS,
    beneficiarySebrae,
  }
}

export default mountTrial
