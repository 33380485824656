import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import PropTypes from 'prop-types'
import { reverse } from 'named-urls'

import { Box } from '@material-ui/core'

import { LoadingFeedback, ButtonComponent } from 'components'
import {
  Contract,
  GenerateContract,
  Facture,
  ContractDetails,
  Partner,
  ReadjustmentFidelity,
  DpoService,
} from './components'

import { useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'

import schema from './schema'
import * as service from 'service'

import { routes } from 'Routes'

const ContractForm = ({ paymentContract, refresh, mode, preRegistration }) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [platformContract, setPlatformContract] = useState(null)
  const [contractFile, setContractFile] = useState(null)

  const history = useHistory()

  const isShow = mode === 'show'
  const isNew = mode === 'new'

  const dateNow = new Date()

  const handleRedirect = (contractId = null) => {
    const action = isShow ? 'show' : 'edit'

    return history.push(
      reverse(routes.contracts[action], {
        contractId: contractId || paymentContract?.id,
      }),
      { tab: 'contractor' },
    )
  }

  const dataPreRegistration =
    paymentContract?.preRegistration || preRegistration

  const { handleSubmit, control, errors, setValue, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      contractIdentifier:
        paymentContract?.contractIdentifier ||
        `${dateNow.getFullYear()}/${dateNow.getTime()}`,
      preRegistration: dataPreRegistration || null,
      typeService: paymentContract?.typeService || '',
      vertical: paymentContract?.vertical || '',
      typeFacture: dataPreRegistration ? 'existing' : 'newCompany',
      sellerHasEmail: true,
      isPartner: paymentContract?.partner ? true : false,
      partner: paymentContract?.partner || { id: null, name: '' },
      indexType: paymentContract?.paymentContractRenewal?.indexType || 'ipca',
      periodOnMonth:
        paymentContract?.paymentContractRenewal?.periodOnMonth || 12,
      automatic: paymentContract?.paymentContractRenewal?.automatic ?? true,
      fidelity: paymentContract?.paymentContractFidelity?.apply ?? false,
      dpoServiceApply: paymentContract?.paymentDpoService?.apply ?? false,
      dpoServiceEndDate: paymentContract?.paymentDpoService?.endDate || null,
      dpoServicePrice: helpers.formatters.currencyInput(
        paymentContract?.paymentDpoService?.price || '1500.00',
      ),
    },
  })

  const onSubmit = async (data) => {
    if (isShow) return null
    delete data.sellerHasEmail

    const dpoService = helpers.paymentContract.mountDpoService(data)
    data.partnerId = data.isPartner ? data.partner?.id : null

    setLoading(true)
    try {
      if (paymentContract) {
        await service.dponet.paymentContract.update({
          paymentContractId: paymentContract.id,
          paymentContract: { ...data, dpoService },
        })
        snackbar.open({
          message: 'Contrato atualizado com sucesso!',
          variant: 'success',
        })
        refresh()

        handleRedirect(paymentContract?.id)
      } else {
        delete data.generateContract
        if (platformContract === 'custom' && isEmpty(contractFile)) {
          return snackbar.open({
            message: 'Selecione o arquivo do contrato.',
            variant: 'error',
          })
        }
        const response = await service.dponet.paymentContract.create({
          paymentContract: {
            preRegistrationToken: data.preRegistration?.token,
            platformContract,
            ...{ ...data, dpoService },
          },
        })
        const paymentContractId = response?.data?.paymentContract?.id
        if (!isEmpty(contractFile)) {
          await service.dponet.paymentContract.uploadCustomContract({
            paymentContractId,
            contractFile,
          })
        }

        snackbar.open({
          message: 'Contrato criado com sucesso!',
          variant: 'success',
        })

        if (response?.data) return handleRedirect(paymentContractId)
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao salvar o contrato!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const recommendation = watch('preRegistration')

  useEffect(() => {
    if (isNew && recommendation) {
      setValue('vertical', recommendation?.vertical)
      setValue('typeService', recommendation?.typeService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendation])

  return (
    <>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Facture
          control={control}
          errors={errors}
          isNew={isNew}
          watch={watch}
          displaySelected={!isEmpty(dataPreRegistration)}
        />
        {(!isNew || watch('preRegistration')) && (
          <>
            <Contract
              control={control}
              errors={errors}
              disabled={isShow}
              codeContractOmie={paymentContract?.codeContractOmie}
              paymentContract={paymentContract}
              refresh={refresh}
              setValue={setValue}
              watch={watch}
            />
            <DpoService
              disabled={isShow}
              control={control}
              errors={errors}
              watch={watch}
            />
            <ReadjustmentFidelity
              disabled={isShow}
              control={control}
              errors={errors}
              watch={watch}
              paymentContract={paymentContract}
              refresh={refresh}
              setValue={setValue}
            />
            <Partner
              control={control}
              errors={errors}
              disabled={isShow}
              watch={watch}
              paymentContract={paymentContract}
            />
            {paymentContract ? (
              <ContractDetails
                platformContract={paymentContract.platformContract}
              />
            ) : (
              <GenerateContract
                control={control}
                errors={errors}
                setPlatformContract={setPlatformContract}
                disabled={!isNew}
                platformContract={
                  paymentContract?.platformContract ?? platformContract
                }
                setFile={setContractFile}
              />
            )}
            {!isShow && (
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <ButtonComponent title="Salvar & próximo" />
              </Box>
            )}
          </>
        )}
      </form>
      {isShow && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <ButtonComponent
            title="Próximo"
            type="button"
            onClick={() => handleRedirect(null)}
          />
        </Box>
      )}
    </>
  )
}

ContractForm.propTypes = {
  paymentContract: PropTypes.object,
  preRegistrationToken: PropTypes.string,
  mode: PropTypes.string,
  refresh: PropTypes.func,
}

export default ContractForm
