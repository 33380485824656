import { useState } from 'react'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import {
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  Chip,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  Card,
  Select as SelectComponent,
  LoadingFeedback,
  ButtonComponent,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import constants from 'constants/index'

const Form = ({ seller, disabled }) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      status: seller?.status ?? true,
      name: seller?.name || '',
      email: seller?.email || '',
      kind: seller?.kind || constants.seller.SELLER_KIND,
      document: helpers.formatters.cnpjAndCpfMask(seller?.document) || '',
      intervalInMonth: seller?.intervalInMonth || 1,
    },
  })

  const sellerKind = watch('kind') === constants.seller.SELLER_KIND

  const handleReturn = () => history.push(routes.sellers.all)

  const handleRedirect = (sellerId) => {
    if (seller) return handleReturn()

    history.push(
      reverse(routes.sellers.edit, {
        sellerId,
      }),
    )
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      const method = seller ? 'update' : 'create'

      const response = await service.dponet.sellers[method]({
        ...data,
        sellerId: seller?.id,
      })
      snackbar.open({
        message: `${
          constants.seller.TRANSLATED_KINDS[data.kind]
        } criado com sucesso!`,
        variant: 'success',
      })

      if (response?.data) handleRedirect(response?.data?.seller?.id)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          `Falha em criar ${constants.seller.TRANSLATED_KINDS[data.kind]}!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback loading={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          title="Preencha os dados"
          actionButton={
            !disabled ? null : (
              <Chip
                variant="outlined"
                color="primary"
                label={`Nível ${seller?.higherLevel}`}
              />
            )
          }
        >
          <Box py={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={disabled}
                  control={
                    <Controller
                      as={<Switch color="primary" />}
                      name="status"
                      control={control}
                      onChange={([event]) => event.target.checked}
                    />
                  }
                  label="Ativo"
                />
              </Grid>
              <Grid item xs={12} sm={sellerKind ? 12 : 6}>
                <Controller
                  as={
                    <TextField
                      label="Nome"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                      disabled={disabled}
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  as={
                    <TextField
                      label="E-mail"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      fullWidth
                      disabled={disabled}
                    />
                  }
                  control={control}
                  name="email"
                  mode="onBlur"
                />
              </Grid>

              {!sellerKind && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      as={
                        <TextField
                          label="CNPJ/CPF"
                          type="text"
                          color="primary"
                          variant="outlined"
                          error={!!errors?.document}
                          helperText={errors?.document?.message}
                          fullWidth
                          disabled={disabled}
                        />
                      }
                      control={control}
                      name="document"
                      mode="onChange"
                      onChange={([event]) =>
                        helpers.formatters.cnpjAndCpfMask(event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      as={
                        <TextField
                          label="Intervalo de repasse"
                          type="number"
                          color="primary"
                          variant="outlined"
                          error={!!errors?.intervalInMonth}
                          helperText={errors?.intervalInMonth?.message}
                          fullWidth
                          InputProps={{
                            endAdornment: 'Meses',
                          }}
                          disabled={disabled}
                        />
                      }
                      control={control}
                      name="intervalInMonth"
                      mode="onBlur"
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={sellerKind ? 6 : 3}>
                <Controller
                  as={
                    <SelectComponent
                      items={constants.seller.OPTIONS}
                      label="Tipo"
                      error={!!errors.kind}
                      fullWidth
                      helperText={errors?.kind?.messsage}
                      disabled={disabled}
                    />
                  }
                  control={control}
                  name="kind"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Box display="flex" justifyContent="flex-end">
          <Box pr={2}>
            <ButtonComponent
              title="Voltar"
              type=" button"
              variant="outlined"
              onClick={handleReturn}
            />
          </Box>
          {!disabled && <ButtonComponent title="Salvar" />}
        </Box>
      </form>
    </>
  )
}

Form.propTypes = {
  sale: PropTypes.object,
}

export default Form
