import { useState } from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { Controller } from 'react-hook-form'

import { Card, Label, InfoDialog } from 'components'

import constants from 'constants/index'
import useStyles from './styles'

const MaturityOption = ({ control, disabled, defaultExpanded, ...rest }) => {
  const [openInfo, setOpenInfo] = useState(false)
  const classes = useStyles()

  const dpoServiceHelper = () => setOpenInfo(true)

  return (
    <Box {...rest}>
      <Card
        title="Opções de vencimento"
        dropdown
        defaultExpanded={defaultExpanded}
        dropdownChildrenPadding={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label title="Regra de vencimento após a emissão:">
              <Controller
                as={
                  <RadioGroup>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="false"
                        control={<Radio color="primary" />}
                        label="Sem regra de vencimento (padrão)"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="true"
                        control={<Radio color="primary" />}
                        label="Vencimento no próximo mês após a emissão"
                      />
                    </Box>
                  </RadioGroup>
                }
                control={control}
                name="expirationLastMonth"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label
              title={
                <Box display="flex" alignItems="center">
                  Procedimento para casos em que o vencimento ocorra em um fim
                  de semana.
                  <Box className={classes.iconBtn} onClick={dpoServiceHelper}>
                    <InfoIcon color="primary" size={10} />
                  </Box>
                </Box>
              }
            >
              <Controller
                as={
                  <RadioGroup>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="postpone"
                        control={<Radio color="primary" />}
                        label="Prorrogar para depois do fim de semana (padrão)"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="anticipate"
                        control={<Radio color="primary" />}
                        label="Antecipar para antes do fim de semana"
                      />
                    </Box>
                  </RadioGroup>
                }
                control={control}
                name="expirationAction"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label title="Dia de vencimento">
              <Controller
                as={
                  <RadioGroup>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="fixed"
                        control={<Radio color="primary" />}
                        label="Dia fixo de Vencimento (padrão)"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="dynamic"
                        control={<Radio color="primary" />}
                        label="Numero de dias após a emissão"
                      />
                    </Box>
                  </RadioGroup>
                }
                control={control}
                name="typeBillingDay"
                mode="onBlur"
              />
            </Label>
          </Grid>
        </Grid>
      </Card>
      {openInfo && (
        <InfoDialog
          open={openInfo}
          setOpen={setOpenInfo}
          title="Procedimento para casos em que o vencimento ocorra em um fim de semana"
          text={constants.paymentContract.DESCRIPTION_POSTPONE_OR_ANTICIPATE}
        />
      )}
    </Box>
  )
}

export default MaturityOption
