import dataTreatmentAgent from 'constants/dataTreatmentAgent'

const separateTypeAgents = (dataTreatmentAgents) => {
  const {
    CONTROLLER_TYPE,
    OPERATOR_TYPE,
    SUBOPERATOR_TYPE,
    CO_CONTROLLER_TYPE,
  } = dataTreatmentAgent

  const controller = []
  const operator = []
  const suboperator = []
  const coController = []

  // eslint-disable-next-line
  dataTreatmentAgents?.map((dataTreatmentAgent) => {
    if (dataTreatmentAgent?.agentType === CONTROLLER_TYPE) {
      controller.push(dataTreatmentAgent)
    }

    if (dataTreatmentAgent?.agentType === OPERATOR_TYPE) {
      operator.push(dataTreatmentAgent)
    }

    if (dataTreatmentAgent?.agentType === SUBOPERATOR_TYPE) {
      suboperator.push(dataTreatmentAgent)
    }
    if (dataTreatmentAgent?.agentType === CO_CONTROLLER_TYPE) {
      coController.push(dataTreatmentAgent)
    }
  })

  return { controller, operator, suboperator, coController }
}

export default separateTypeAgents
