import { useEffect } from 'react'
import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Controller } from 'react-hook-form'

import { Card, Select, Plans, Seller } from 'components'
import { CompanyCompany } from '../'

import constants from 'constants/index'

const TypeCompany = ({
  typeIsTrial,
  errors,
  control,
  setValue = () => {},
  watch = () => {},
  preRegistration,
  isEdit,
}) => {
  const {
    CONSULTIVE_SALES_LABEL,
    PUBLIC_AGENCY_LABEL,
    VERTICAL_OPTIONS,
    TYPE_SERVICES_OPTIONS,
    SERVICES_LABEL,
  } = constants.paymentContract

  const { TRIAL_TYPES_OPTIONS } = constants.preRegistration

  const isConsultiveSales = watch('typeService') === CONSULTIVE_SALES_LABEL
  const isPublicAgency = watch('vertical') === PUBLIC_AGENCY_LABEL
  const verticalOptions = VERTICAL_OPTIONS[watch('typeService')] || []

  useEffect(() => {
    if (isConsultiveSales && !isPublicAgency) {
      setValue('vertical', PUBLIC_AGENCY_LABEL)
    }

    if (!isConsultiveSales && isPublicAgency) {
      setValue('vertical', SERVICES_LABEL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('typeService')])

  return (
    <Card title="Ficha Corporativa">
      <Grid container spacing={2}>
        <Controller
          control={control}
          name="type"
          mode="onChange"
          as={<input type="hidden" />}
        />
        {typeIsTrial && (
          <>
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    label="Período de Teste"
                    color="primary"
                    format="DD/MM/yyyy"
                    variant="outlined"
                    error={!!errors.endDate}
                    helperText={errors?.endDate?.message}
                    fullWidth
                  />
                }
                control={control}
                name="endDate"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="typeTrial"
                mode="onBlur"
                type="text"
                as={
                  <Select
                    label="Tipo"
                    items={TRIAL_TYPES_OPTIONS}
                    fullWidth
                    error={!!errors?.typeTrial}
                    helperText={errors?.typeTrial?.message}
                  />
                }
              />
            </Grid>
          </>
        )}
        <Plans
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          isEdit={isEdit}
        />
        <CompanyCompany
          control={control}
          companyTemplateName={preRegistration?.companyTemplate?.name}
          watch={watch}
        />
        <Controller
          as={<input type="hidden" />}
          control={control}
          name="typeClient"
          mode="onBlur"
        />
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="typeService"
            mode="onChange"
            type="text"
            as={
              <Select
                label="Tipo de Serviço"
                items={TYPE_SERVICES_OPTIONS}
                fullWidth
                error={!!errors?.typeService}
                helperText={errors?.typeService?.message}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            disabled={isConsultiveSales}
            control={control}
            name="vertical"
            mode="onChange"
            type="text"
            as={
              <Select
                label="Vertical"
                items={verticalOptions}
                fullWidth
                error={!!errors?.vertical}
                helperText={errors?.vertical?.message}
              />
            }
          />
        </Grid>
        <Seller
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Controller
                as={<Switch color="primary" />}
                name="beneficiarySebrae"
                control={control}
                onChange={([event]) => event.target.checked}
              />
            }
            label="Parceiro Sebrae"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default TypeCompany
