import { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'

import { BaseDialog, LoadingFeedback } from 'components'

import * as service from 'service'
import helpers from 'helpers'

import schema from './schema'

const DialogSebrae = ({ open, setOpen }) => {
  const [isLoading, setIsloading] = useState(false)
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      document: '',
    },
  })

  const onSubmit = async (data) => {
    setIsloading(true)
    try {
      const response = await service.dponet.sebrae.get({
        document: data.document,
      })

      let isSebraePartner = response?.data?.status === true

      snackbar.open({
        message: `Esse CNPJ/CPF ${
          response?.data?.status ? 'é' : 'não é'
        } de um parceiro Sebrae!`,
        variant: isSebraePartner ? 'success' : 'warning',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao verificar CNPJ/CPF do parceiro Sebrae',
        variant: 'error',
      })
    } finally {
      setOpen(false)
      setIsloading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <BaseDialog
        title="Parceria Sebrae"
        actionButtonText="Verificar"
        closeButtonText="Cancelar"
        open={open}
        setOpen={setOpen}
        formRef="verify-document-by-sebrae"
      >
        <form onSubmit={handleSubmit(onSubmit)} id="verify-document-by-sebrae">
          <Grid container spacing={2}>
            <Grid item xs="12">
              <Controller
                as={
                  <TextField
                    label="CNPJ/CPF"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors?.document}
                    helperText={errors?.document?.message}
                    fullWidth
                  />
                }
                control={control}
                name="document"
                onChange={([event]) =>
                  helpers.formatters.cnpjAndCpfMask(event.target.value)
                }
                mode="onChange"
              />
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  )
}

DialogSebrae.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default DialogSebrae
