import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import helpers from 'helpers'

const VisiblePaymentPaid = ({ paymentOrder }) => {
  const paid = paymentOrder?.paidDate
  const paidClient = paymentOrder?.paidDateClient
  const withdrawal = paymentOrder?.withdrawalDate

  return (
    <>
      {!paid && !paidClient && !withdrawal && (
        <Typography variant="body2">-</Typography>
      )}
      {paid && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="body2">
            Pago (gateway) {helpers.formatters.date(paid)}
          </Typography>
        </Box>
      )}
      {paidClient && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="body2">
            Pago (cliente) {helpers.formatters.date(paidClient)}
          </Typography>
        </Box>
      )}
      {withdrawal && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography variant="body2">
            Saque em {helpers.formatters.date(withdrawal)}
          </Typography>
        </Box>
      )}
    </>
  )
}

VisiblePaymentPaid.propTypes = {
  paymentOrder: PropTypes.object.isRequired,
}

export default VisiblePaymentPaid
