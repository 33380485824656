import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'

import { LoadingButton, Select } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import useStyles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'

const CreateDialog = ({ integrationPartnerId, open, handleClose, refresh }) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      url: '',
      event: constants.integrationPartner.WEBHOOK_EVENTS.titular_ticket,
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)
    console.log(data)

    try {
      await service.dponet.integrationPartnerWebhooks.create({
        integrationPartnerId,
        integrationPartnerWebhook: data,
      })

      refresh()
      handleClose()
      snackbar.open({
        message: 'Webhook criado com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao criar webhook',
        variant: 'error',
      })
    }

    setLoading(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="last-token-destroy"
      fullWidth
    >
      <DialogTitle>
        <Typography className={classes.textTitle}>Criar Webhook</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="webhook-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                as={<TextField fullWidth label="URL (com https://)" />}
                control={control}
                name="url"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="event"
                mode="onBlur"
                as={
                  <Select
                    label="Tipo"
                    items={
                      constants.integrationPartner.SELECT_WEBHOOK_EVENTS || []
                    }
                    error={!!errors?.event}
                    helperText={errors?.event?.message}
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button type="button" variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          form="webhook-form"
          variant="contained"
          type="submit"
          color="primary"
          text="CRIAR"
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  )
}

CreateDialog.defaultProps = {
  integrationPartnerToken: {},
  setOpen: () => {},
  refresh: () => {},
}

export default CreateDialog
