import { isEmpty } from 'lodash'

import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Box,
} from '@material-ui/core'
import { Label } from 'components'

import dataTreatmentAgent from 'constants/dataTreatmentAgent'
import helpers from 'helpers'
import useStyles from './styles'

const DataTreatmentAgent = ({ dataTreatmentAgents }) => {
  const {
    CONTROLLER_LABEL,
    OPERATOR_LABEL,
    SUBOPERATOR_LABEL,
    CO_CONTROLLER_LABEL,
  } = dataTreatmentAgent

  const agents =
    helpers.dataTreatmentAgent.separateTypeAgents(dataTreatmentAgents)

  const classes = useStyles()

  return (
    <Card className={classes.rootCard}>
      <CardHeader title="Agentes de tratamento" />
      <Divider />
      <Box m={2.5}>
        {isEmpty(dataTreatmentAgents) ? (
          <Typography variant="body2">
            Nenhum agente de tratamento encontrado
          </Typography>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Label title={CONTROLLER_LABEL}>
                {agents?.controller?.map((agent) => (
                  <Typography variant="body2">{agent?.name}</Typography>
                ))}
              </Label>
            </Grid>

            <Grid item xs={12}>
              <Label title={OPERATOR_LABEL}>
                {agents?.operator?.map((agent) => (
                  <Typography variant="body2">{agent?.name}</Typography>
                ))}
              </Label>
            </Grid>

            <Grid item xs={12}>
              <Label title={SUBOPERATOR_LABEL}>
                {agents?.suboperator?.map((agent) => (
                  <Typography variant="body2">{agent?.name}</Typography>
                ))}
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title={CO_CONTROLLER_LABEL}>
                {agents?.coController?.map((agent) => (
                  <Typography variant="body2">{agent?.name}</Typography>
                ))}
              </Label>
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  )
}

export default DataTreatmentAgent
