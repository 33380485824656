import { useState } from 'react'
import { Box, Button, TablePagination, useMediaQuery } from '@material-ui/core'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
  Card,
} from 'components'
import { ChurnCreateModal, ChurnsTable, ChurnFilter } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import { usePagination } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'
import theme from 'theme'

const ChurnsMain = () => {
  const filter = useFilter()

  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleOpenModal = () => setOpenModal((prevOpen) => !prevOpen)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.churns.get,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Listagem de solicitações de churns">
      <LoadingFeedback open={isLoading || loading} />
      <Container maxWidth={false}>
        <ContentHeader
          menu="Churns"
          title="Listagem"
          subtitle="Solicitações de churns"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Card
          title="Solicitações de Churns"
          actionButton={
            <Permitted tag={constants.permissions.CHURNS.MANAGE}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<PlusIcon />}
                onClick={handleOpenModal}
              >
                Criar
              </Button>
            </Permitted>
          }
        >
          <ChurnsTable
            churns={response?.data?.churns || []}
            refresh={refresh}
            setIsLoading={setLoading}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      {openModal && (
        <ChurnCreateModal open={openModal} setOpen={setOpenModal} />
      )}

      <ChurnFilter filter={filter} />
    </Page>
  )
}

export default ChurnsMain
